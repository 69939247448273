.questionnaire-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 20px;
}

.progress {
    height: 100%;
    background-color: #4CAF50;
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
}

.question-content {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.question-title {
    color: #333;
    margin-bottom: 15px;
}

.question-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.question-text {
    margin-bottom: 20px;
}

.option-container {
    margin-bottom: 10px;
}

.option-input {
    margin-right: 10px;
}

.option-label {
    cursor: pointer;
}

.other-input {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.previous-button, .next-button, .submit-button, .start-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.previous-button {
    background-color: #f0f0f0;
    color: #333;
}

.next-button, .submit-button, .start-button {
    background-color: #4CAF50;
    color: white;
}

.completion-step {
    text-align: center;
}

.completion-title {
    color: #4CAF50;
    margin-bottom: 20px;
}

.recommendations-container {
    background-color: #e8f5e9;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.recommendations-intro {
    font-weight: bold;
    margin-bottom: 10px;
}

.recommendations-list {
    list-style-type: none;
    padding: 0;
}

.recommendation-item {
    margin-bottom: 10px;
    text-align: left;
}

.recommendation-icon {
    color: #4CAF50;
    margin-right: 10px;
}

.disclaimer {
    font-style: italic;
    color: #666;
    margin-top: 20px;
}

.hero-section {
    background-color: #f0f8ff;
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px;
}

.container {
    max-width: 600px;
    margin: 0 auto;
}

.hero-content {
    text-align: center;
}

.search-form {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
}

.submit-button {
    border-radius: 0 4px 4px 0;
}

.error {
    color: red;
    margin-top: 10px;
}

.guide-step {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.guide-title {
    color: #4CAF50;
    font-size: 32px;
    margin-bottom: 25px;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
}

.guide-image-container {
    width: 100%;
    margin: 20px 0;
}

.guide-image {
    width: 100%;
    max-width: 400px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.guide-intro {
    font-family: 'Helvetica', Arial, sans-serif;
    color: #333;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 25px;
    text-align: left;
    font-weight: 500;
}

.guide-list {
    text-align: left;
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 30px;
}

.guide-list li {
    margin-bottom: 15px;
    font-family: 'Helvetica', Arial, sans-serif;
    color: #555;
    font-size: 16px;
    padding-left: 30px;
    position: relative;
}

.guide-list li::before {
    content: '✓';
    position: absolute;
    left: 0;
    color: #4CAF50;
    font-weight: bold;
}

.start-button {
    background-color: #4CAF50;
    color: white;
    padding: 14px 28px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.start-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.reset-button {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
}

.reset-button:hover {
    background-color: #d32f2f;
}
