.navbar {
  background-color: #222831;
  color: rgb(255, 255, 255);
  padding: 1rem 1.5rem;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  position: relative;
}

.navbar-logo {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.logo-image {
  height: 60px;
  width: auto;
  margin-right: 10px;
}

.site-title {
  color: white;
  text-decoration: none;
  font-size: 1.7rem;
  font-weight: bold;
}

.site-title:hover {
  color: #f3eccf;
}

.menu-icon {
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: white;
  position: absolute;
  right: 20px;
}

.navbar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  transition: all 0.3s ease-in-out;
  margin-left: auto;
}

.navbar-menu.open {
  display: flex;
}

.navbar-menu li {
  margin: 0 1.4rem;
}

.navbar-menu a {
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: bold;
}

.navbar-menu a:hover {
  color: #f3eccf;
}

.navbar-menu .active a {
  color: #ECCEAE;
}

/* 为移动设备定义样式 */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 20px;
  }

  .navbar-logo {
    margin-right: 120px;
  }


  .navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: left;
  }


  .navbar-menu.open {
    display: flex;
  }

  .navbar-menu li {
    margin: 10px 0;
  }

  .navbar-menu a {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .site-title {
    font-size: 1.5rem;
  }

  .logo-image {
    height: 50px;
  }

  .navbar-menu a {
    font-size: 1rem;
  }
}